import React, { useContext, useEffect, useState } from "react";
import { UserContext, handleLogin, isLoggedIn } from "@parallelpublicworks/entitysync";
import { Form, TextInput, Button, Loading } from "carbon-components-react";
import { Link, graphql, navigate } from "gatsby";
import { InlineNotification } from "gatsby-theme-carbon";

export default function Index({ location }) {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);

  useEffect(() => {
    if (typeof userContext[0] !== `undefined` && isLoggedIn(userContext)) {
      const redirectPath = location.state ? location.state.referrer : false;
      navigate(redirectPath ? redirectPath : "/questions");
    }
  }, [location, userContext]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setHasError(null);
    try {
      handleLogin(userContext, e);
      setTimeout(() => {
        setIsLoading(false);
        setHasError("An error occurred");
      }, 6000);
    } catch (error) {
      setHasError(error);
    }
  };

  return (
    <Form className="aupo-login-form" action="/questions/" onSubmit={handleSubmit}>
      {isLoading && <Loading />}
      <h2>Login</h2>
      {hasError && (
        <>
          <InlineNotification className="empty-test-notification" kind="error">
            {hasError}
          </InlineNotification>
        </>
      )}
      <div>
        <TextInput
          id="username"
          name="username"
          required
          autoComplete="off"
          invalidText="Invalid username"
          labelText="Username:"
        />
      </div>
      <div>
        <TextInput
          type="password"
          id="password"
          name="password"
          required
          autoComplete="off"
          invalidText="Invalid password"
          labelText="Password:"
        />
      </div>
      <div>
        <Button kind="primary" tabIndex={0} type="submit">
          Submit
        </Button>
      </div>
      <div>
        <Link to="/password-reset">Forgot password?</Link>
      </div>
    </Form>
  );
}

export const query = graphql`
  {
    allSitePage(filter: { path: { eq: "/" } }) {
      edges {
        node {
          id
          path
          fields {
            frontmatter {
              description
              keywords
              tabs
              title
            }
          }
        }
      }
    }
  }
`;
